<template>
  <v-main>
    <div class="fill-height">
      <div class="background-element background-image" :style="'background-image: url(' + backgroundUrl + ');'"></div>
      <div class="background-gradient background-element"></div>

      <v-container class="fill-height">
        <div class="pa-0 col-12 col-sm-8 col-md-8 col-lg-6 col-xl-4" style="margin: auto;">

          <div>
            <v-card color="c_background" style="width: 100%">
              <v-card-title>Registrieren</v-card-title>
              <v-card-text>
                <v-form v-model="valid" ref="form">

                  <v-text-field label="E-Mail-Adresse" v-model="email" type="email"
                                :rules="emailRules" :disabled="signUpInProgress" hide-details="auto"></v-text-field>
                  <v-text-field label="Passwort" v-model="password" type="password"
                                :rules="passwordRules" :disabled="signUpInProgress" hide-details="auto"></v-text-field>

                  <PasswordChecker :password="password" :update="updatePasswordStatus"></PasswordChecker>

                  <v-checkbox v-model="privacyPolicy"
                              hide-details="auto" :rules="checkboxRules" :disabled="signUpInProgress">
                    <template v-slot:label>
                      <div>
                        Ich willige ein in die
                        <a target="_blank" href="/privacy-policy" @click.stop>Datenschutzerklärung</a>
                      </div>
                    </template>
                  </v-checkbox>

                  <v-checkbox v-model="customerInfo" class="mt-2"
                              hide-details="auto" :rules="checkboxRules" :disabled="signUpInProgress">
                    <template v-slot:label>
                      <div>
                        Ich habe die
                        <a target="_blank" href="/customer-information" @click.stop>Kundeninformation</a>
                        zur Kenntnis genommen
                      </div>
                    </template>
                  </v-checkbox>

                  <div class="error--text py-3 text-center">{{ errorText }}</div>

                  <div class="d-flex align-center">
                    <v-spacer></v-spacer>
                    <v-btn @click="submit" :disabled="signUpInProgress" color="primary">Account erstellen</v-btn>
                  </div>

                  <v-progress-linear v-if="signUpInProgress" indeterminate color="primary"
                                     class="mt-3"></v-progress-linear>

                  <div class="mt-2">Oder anmelden mit:</div>
                  <SocialLogin></SocialLogin>

                </v-form>

              </v-card-text>
            </v-card>

            <v-card class="mt-3" color="c_background" style="width: 100%">
              <v-card-title class="py-3">
                <div class="d-flex flex-wrap text-h5" style="font-size: 14pt !important; font-weight: 300">
                  <div class="black--text mr-2">Schon dabei?</div>
                  <router-link to="/login" class="">Jetzt einloggen!</router-link>
                </div>
              </v-card-title>
            </v-card>
          </div>

        </div>
      </v-container>

    </div>
  </v-main>
</template>

<script>
import PasswordChecker from "@/components/PasswordChecker";
import SocialLogin from "@/components/SocialLogin";
import firebase from "firebase/compat";
export default {
  name: "SignUp",
  components: { SocialLogin, PasswordChecker },
  data() {
    return {
      backgroundUrl: require("@/assets/images/climber_1080.jpg"),
      email: "",
      password: "",
      privacyPolicy: false,
      customerInfo: false,
      valid: false,
      emailRules: [],
      passwordRules: [],
      checkboxRules: [],
      passwordValid: false,
      signUpInProgress: false,
      errorText: "",
    };
  },
  computed: {
    userIsLoggedIn() {
      return this.$store.getters.userIsLoggedIn;
    }
  },
  mounted() {
    if (this.userIsLoggedIn) {
      this.$router.push("/login");
    }
  },
  methods: {
    submit() {
      // set validation rules
      this.emailRules = [
        v => !!v || "E-Mail Adresse festlegen",
        v => /.+@.+\..+/.test(v) || "E-Mail Adresse ist ungültig"
      ];
      this.passwordRules = [
        v => !!v || "Passwort festlegen",
        () => this.passwordValid || "Das Passwort erfüllt nicht alle Anforderungen"
      ];
      this.checkboxRules = [
        v => v || "Bitte bestätigen"
      ];

      // let the DOM update
      this.$nextTick(() => {
        if (this.$refs.form.validate()) {
          this.signUpInProgress = true;

          firebase.auth().createUserWithEmailAndPassword(this.email, this.password).then(firebaseResponse => {
            this.signUpInProgress = false;
            this.errorText = "";
            let credentials = {
              email: this.email,
              password: this.password
            };
            // noinspection JSCheckFunctionSignatures
            this.$router.push({name: "Login", params: {"credentials": credentials}});
          }).catch(reason => {
            this.signUpInProgress = false;
            let code = reason.code;
            if (code === "auth/email-already-in-use") {
              this.errorText = "Diese E-Mail-Adresse ist bereits registriert";
            } else {
              this.errorText = "Es gab einen Fehler bei der Registrierung: " + code;
            }
          })

        }
      });
    },
    updatePasswordStatus(value) {
      this.passwordValid = value;
    }
  }
};
</script>

<style scoped>

.background-element {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.background-image {
  filter: saturate(80%) contrast(50%) brightness(80%);
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  overflow: hidden;
}

.background-gradient {
  background: rgb(0, 0, 0);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.75) 10%, rgba(0, 0, 0, 0) 55%);
}

</style>